import { RelatedArticleContentValidator } from "../../http/dto/RelatedArticleContent.dto";
import { renderReact } from "../../utils/hydrate";
import { retry } from "../../utils/retry";

document.addEventListener("DOMContentLoaded", async () => {
  const targetElems = document.querySelectorAll(".related-article-slider-target");
  for (const element of targetElems) {
    const dataProp = element.getAttribute("data-prop");
    const data = JSON.parse(dataProp || "{}");
    const articles = await RelatedArticleContentValidator.parseAsync(data);
    const { default: ArticleList } = await retry(() => import("./RelatedArticleSlider"));
    renderReact(element, <ArticleList articleContent={articles} />);
  }
});
