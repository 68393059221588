import { TopBannerListValidator } from "../../http/dto/TopBanners.dto";
import { hydrateReact } from "../../utils/hydrate";
import { retry } from "../../utils/retry";

document.addEventListener("DOMContentLoaded", async () => {
  const scriptElem = document.querySelector(".js-top-banners");
  const targetElem = document.querySelector(".top-banners-target");
  if (scriptElem?.innerHTML && targetElem) {
    const data = JSON.parse(scriptElem.innerHTML);
    const topbanners = TopBannerListValidator.parse(data);

    const { default: TopBanners } = await retry(() => import("./TopBanners"));
    hydrateReact(targetElem, <TopBanners banners={topbanners} />);
  }
});
