import { hydrateReact } from "../shared/utils/hydrate";
import { scriptData } from "../shared/utils/scriptData";
import { isHTMLElement } from "../shared/utils/types/isHTMLElement";
import { HeaderValidator } from "./dto/Header.dto";
import { Header } from "./Header";

const targetElement = document.querySelector("#js-header");

if (isHTMLElement(targetElement)) {
  const data = HeaderValidator.parse(scriptData("js-header-data"));
  hydrateReact(targetElement, <Header {...data} />);
}
