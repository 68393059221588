import clsx from "clsx";
import React, { forwardRef, HTMLAttributes } from "react";

import styles from "./Container.style.scss";

export const Container = forwardRef<HTMLDivElement, React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={clsx(styles.container, props.className)}>
        {children}
      </div>
    );
  },
);
