import clsx from "clsx";
import AlertError from "icons/error.svg";
import AlertCheck from "icons/success.svg";
import AlertWarning from "icons/warning.svg";
import { FunctionComponent } from "react";

import styles from "./StatusIcon.style.scss";

interface IStatusIconProps {
  className?: string;
  type: "error" | "success" | "warning" | "info";
}

export const StatusIcon: FunctionComponent<IStatusIconProps> = ({ type, className }) => {
  if (type === "info") {
    return <AlertError className={clsx(className, styles.info, styles.icon)} />;
  }
  if (type === "warning") {
    return <AlertWarning className={clsx(className, styles.warning, styles.icon)} />;
  }
  if (type === "error") {
    return <AlertError className={clsx(className, styles.error, styles.icon)} />;
  }
  if (type === "success") {
    return <AlertCheck className={clsx(className, styles.success, styles.icon)} />;
  }
};
