import { useMutation, useQuery } from "@tanstack/react-query";

import { useModal } from "../../shared/modules/Modal/ModalContext";
import { usePowerStep } from "../../shared/modules/PowerStep/PowerstepContext";
import { queryClient } from "../../shared/utils/queryClient";
import { CartController } from "../cart-controller";
import { addToCart, emptyCart, fetchCart, removeCartItem, updateCartItem, updateRebateCode } from "../http/cart.http";
import { ICart } from "../http/dto/Cart.dto";

export const CART_QUERY = {
  queryKey: ["cart"],
  queryFn: fetchCart,
  staleTime: 5 * 60 * 1000,
  placeholderData: {
    items: [],
    price: 0,
    promotions: [],
    non_items: [],
    logged_in: false,
    is_offer: false,
    secure_id: "-1",
    estimated_shipping_fee: 0,
  } as ICart,
} as const;

export const CART_AMOUNT_QUERY = {
  ...CART_QUERY,
  enabled: false, // Ensures this query won't trigger a fetch, and only listen to updates from other queries.
  select: (data: ICart) => {
    return data.items.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  },
} as const;

/**
 * Hook that setups the react-query mutators used to update the cart
 */
export const useCartMutations = () => {
  const add = useMutation({
    mutationKey: ["cart", "add"],
    mutationFn: addToCart,
    onSuccess: (data: ICart) => {
      queryClient.setQueryData<ICart>(["cart"], data);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
  const update = useMutation({
    mutationKey: ["cart", "update"],
    mutationFn: updateCartItem,
    onSuccess: (data) => {
      queryClient.setQueryData<ICart>(["cart"], data);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
  const remove = useMutation({
    mutationKey: ["cart", "remove"],
    mutationFn: removeCartItem,
    onSuccess: (data: ICart) => {
      queryClient.setQueryData<ICart>(["cart"], data);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
  const rebateCode = useMutation({
    mutationKey: ["cart", "rebateCode"],
    mutationFn: updateRebateCode,
    onSuccess: (data: ICart) => {
      queryClient.setQueryData<ICart>(["cart"], data);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
  const empty = useMutation({
    mutationKey: ["cart", "empty"],
    mutationFn: emptyCart,
    onSuccess: (data: ICart) => {
      queryClient.setQueryData<ICart>(["cart"], data);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
  return { add, update, remove, rebateCode, empty };
};

/**
 * Hook that returns the cart query
 */
export const useCartQuery = (props?: { enabled: boolean }) => {
  return useQuery({ ...CART_QUERY, enabled: props?.enabled });
};

/**
 * Hook that returns the cart quantity query
 */
export const useCartAmountQuery = (props?: { enabled: boolean }) => {
  return useQuery({ ...CART_AMOUNT_QUERY, ...props });
};

export const useCartController = () => {
  const modal = useModal();
  const mutator = useCartMutations();
  const powerstep = usePowerStep();

  return new CartController(modal, mutator, powerstep);
};
