import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { StatusIcon } from "../../../components/StatusIcon";
import { ModalSize, ModalType } from "../Modal";
import styles from "./ModalHeader.style.scss";

interface IModalHeaderProps {
  title: string;
  className?: string;
  subtitle?: string;
  size?: ModalSize;
  type?: ModalType;
}

const ModalHeader: FunctionComponent<React.PropsWithChildren<IModalHeaderProps>> = ({
  children,
  title,
  className,
  subtitle,
  size,
  type,
  ...props
}) => {
  return title ? (
    <div className={clsx(className, styles[`size-${size ?? "default"}`], type && styles[type])} {...props}>
      <div className={styles.headerContainer}>
        {type && (
          <div className={styles.icon}>
            <StatusIcon type={type === "default" ? "error" : type} />
          </div>
        )}
        {size === "fullscreen" ? (
          <h2 className={styles.fullscreenHeader}>{title}</h2>
        ) : (
          <div className={styles.header}>{title}</div>
        )}
      </div>
      {subtitle ? <h6 className={styles.subtitle}>{subtitle}</h6> : undefined}
      {children}
    </div>
  ) : (
    ""
  );
};

export const _ModalHeader = ModalHeader;
