import { t } from "ttag";

import * as Sentry from "./LazySentry";

export function retry<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 500): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function retryOrReload<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 500): Promise<T> {
  if (SETTINGS.DEBUG) {
    return fn();
  }
  return new Promise<T>((resolve, reject) => {
    retry(() => fn(), retriesLeft, interval)
      .then(resolve)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        const reload = confirm(t`Der var en fejl under indlæsning af siden.\nGenindlæs siden?`);
        if (reload) {
          window.location.reload();
        }
        reject(error);
      });
  });
}
