import isHotkey from "is-hotkey";
import React, { FunctionComponent, startTransition, useEffect, useState } from "react";

import { AdminProvider, IAdminHotkey, IAdminMenuElements } from "./AdminContext";
import AdminMenu from "./AdminMenu";

const AdminManager: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [menuElements, setMenuElements] = useState<IAdminMenuElements>({});
  const [hotkeys, setHotkeys] = useState<IAdminHotkey>({});
  const [isAdmin, setAdmin] = useState(false);
  const [isAoInternal, setIsAoInternal] = useState(false);

  useEffect(() => {
    startTransition(() => {
      if (!global.window) {
        return;
      }
      if (window.IS_STAFF) {
        setAdmin(true);
      }
      if (window.IS_AOINTERNAL) {
        setIsAoInternal(true);
      }
    });
  }, []);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      for (const [hotkey, callback] of Object.entries(hotkeys)) {
        if (isHotkey(hotkey)(event) && isAdmin) {
          callback(event);
        }
      }
    };

    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [hotkeys, isAdmin]);

  return (
    <AdminProvider
      value={{
        Admin: {
          setMenuElements,
          setHotkeys,
          isAdmin,
          isAoInternal,
        },
      }}
    >
      {isAdmin && (
        <AdminMenu>
          {Object.entries(menuElements).map(([key, element]) => (
            <React.Fragment key={key}>{element}</React.Fragment>
          ))}
        </AdminMenu>
      )}
      {children}
    </AdminProvider>
  );
};

export default AdminManager;
