import clsx from "clsx";
import MagnifyingGlass from "icons/search.svg";
import React, { FunctionComponent, Suspense, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { t } from "ttag";

import { useDebouncedEffect } from "../../shared/hooks/useDebouncedEffect";
import { Analytics } from "../../shared/utils/analytics/AnalyticsEvents";
import { retry } from "../../shared/utils/retry";
import styles from "./SearchInput.style.scss";

interface ISearchInputProps {
  className?: string;
  isScrolled: boolean;
}

const SearchDropdown = React.lazy(() => retry(() => import("./SearchDropdown")));

export const SearchInput: FunctionComponent<ISearchInputProps> = ({ className, isScrolled }) => {
  const [query, setQuery] = useState<string>();
  const [activeHit, setActiveHit] = useState<string>();

  useDebouncedEffect(
    () => {
      if (!query) {
        return;
      }

      Analytics.event("action", "search").dispatchEvent({
        source_url: window.location.href,
        source_type: "quicksearch",
        filters: { query: query },
      });
    },
    [query],
    500,
  );

  const onKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
    }
  };

  const onSearchSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (!query || query.length === 0) {
      return;
    }
    if (activeHit) {
      window.location.replace(activeHit);
      return;
    }
    const url = new URL("/search/", window.location.origin);
    url.searchParams.append("search", query);

    window.location.replace(url.toString());
  };

  return (
    <form
      action={"/search"}
      method={"GET"}
      className={clsx(styles.wrapper, className)}
      onSubmit={onSearchSubmit}
      data-a-type={"quicksearch"}
      data-a-name={query}
    >
      <button type={"submit"} className={styles.magnifyingGlass} aria-label={t`Søg`}>
        <MagnifyingGlass onClick={onSearchSubmit} />
      </button>
      <input
        type={"text"}
        name={"search"}
        className={styles.input}
        placeholder={t`Søg efter varer her...`}
        onChange={(event) => setQuery(event.target.value)}
        onKeyDown={onKeydown}
        maxLength={200}
      />
      <ErrorBoundary fallback={null}>
        {query && (
          <Suspense fallback={null}>
            <SearchDropdown isScrolled={isScrolled} query={query} setActiveHit={setActiveHit} />
          </Suspense>
        )}
      </ErrorBoundary>
    </form>
  );
};
