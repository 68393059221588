import merge from "lodash/merge";
import { z } from "zod";

import { safewindow } from "../../iu/shared/utils/safewindow";
import * as Sentry from "../utils/LazySentry";
import { ErrorResponseValidator } from "./dto/BaseResponse.dto";

type IUrlParameters = Record<string, (string | number | undefined) | (string | number)[]>;

interface IRequestOptions {
  defaultHeaders?: boolean;
  allowErrors?: boolean;
}

export class HTTPError extends Error {
  message: string;
  response: Response;
  traceId: string | undefined;
  responseText: string;

  constructor(message: string, response: Response, traceId: string | undefined, responseText: string) {
    super(message);
    this.message = message;
    this.response = response;
    this.traceId = traceId;
    this.responseText = responseText;
  }
}

export class AnnotatedHTTPError extends HTTPError {
  errorMessages: string;
  errorCode: string;
  data: unknown;

  constructor(
    errorMessage: string,
    response: Response,
    traceId: string | undefined,
    responseText: string,
    errorCode: string,
    message: string,
    data: unknown,
  ) {
    super(message, response, traceId, responseText);
    this.errorMessages = errorMessage;
    this.errorCode = errorCode;
    this.data = data;
  }
}

export async function request(req: RequestInfo, init?: RequestInit, options?: IRequestOptions) {
  let opts: RequestInit = init ? init : {};
  if (options?.defaultHeaders ?? true) {
    const defaultHeaders: HeadersInit = {
      Accept: "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    };

    if (!(init?.body instanceof FormData)) {
      defaultHeaders["Content-Type"] = "application/json";
    }

    opts = merge(
      {
        headers: defaultHeaders,
        credentials: "same-origin",
      },
      init,
    );
  }
  const res = await fetch(req, opts);
  Sentry.addBreadcrumb({
    type: "request",
    level: res.ok ? "info" : "error",
    message: `${init?.method ?? "GET"}: ${typeof req === "string" ? req : req.url}`,
  });
  if (!res.ok && !options?.allowErrors) {
    let errorData;
    const traceId = res.headers.get("x-trace-id") ?? undefined;
    try {
      errorData = ErrorResponseValidator(z.unknown()).parse(await res.clone().json());
      // eslint-disable-next-line no-empty
    } catch {}

    if (errorData) {
      throw new AnnotatedHTTPError(
        `Error [${res.status} ${res.statusText}] while requesting "${res.url}" `,
        res,
        traceId,
        await res.clone().text(),
        errorData.error_code,
        errorData.message ?? "",
        errorData.data,
      );
    } else {
      throw new HTTPError(
        `Error [${res.status} ${res.statusText}] while requesting "${res.url}" `,
        res,
        traceId,
        await res.clone().text(),
      );
    }
  }
  return res;
}

export function url(path: string, parameters: IUrlParameters) {
  const x = new URL(path, safewindow("location", {})?.origin);

  Object.entries(parameters).forEach(([key, val]) => {
    if (val) {
      x.searchParams.append(key, val.toString());
    }
  });
  return x.href;
}

export function formData(data: Record<string, string>) {
  return Object.entries(data).reduce((collector, [key, value]) => {
    collector.append(key, value);
    return collector;
  }, new FormData());
}
