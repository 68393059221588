import clsx from "clsx";
import { CSSProperties, FunctionComponent } from "react";

import styles from "./LoadingAnimation.style.scss";

interface ILoadingProps {
  size?: number;
  className?: string;
  style?: CSSProperties;
}

export const LoadingAnimation: FunctionComponent<ILoadingProps> = ({ size, className, style }) => {
  return (
    <div style={{ paddingTop: size, paddingBottom: size, ...style }} className={styles.content}>
      <div className={clsx(styles.laBallSpinClockwiseFade, className)}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
